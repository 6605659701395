import { environment } from '../../../environments/environment';

export const BaseUrl = {
    AUTHENTICATE: environment.apiURL + '/sequation-authentication-api',
    AUTHORIZATION: environment.apiURL + '/sequation-authorization-api',
    USER_VALIDATION: environment.apiURL + '/user-validation-api',
    ORGANIZATION: environment.apiURL + '/sequation-organization-api',
    USER: environment.apiURL + '/sequation-usermanagement-composer-api',
    USER_MANAGEMENT: environment.apiURL + '/sequation-user-management-api',
    CONTENT_MANAGEMENT: environment.apiURL +'/sequation-content-management-api',
    HEALTH_SERVICES: environment.apiURL + '/sequation-health-services-api',
    ASSESSMENT : environment.apiURL + '/sequation-assessment-api',
    JOBS : environment.apiURL + '/sequation-job-api',
    OPENVIDU : environment.apiURL + '/sequation-openvidu-api',
    LOGO: environment.logo,
    BRAND_LOGO: environment.brandLogo,
    APPTITLE: environment.appTitle,
    SRM: environment.apiURL + '/sequation-srm-api',
    CVB: environment.apiURL + '/sequation-cv-builder-api',
    Document: environment.apiURL + '/sequation-document-api',
    PLAN: environment.apiURL + '/sequation-plan-api',
    MOJ_PEF: environment.apiURL + '/sequation-moj-pef-contract-api',
    Moodle:  environment.apiURL + '/sequation-moodle-api',
    IOMAD_URL: environment.apiURL + '/iomad',
    WORKSHOP_MANAGEMENT: environment.apiURL + '/sequation-moj-pef-workshop-api',
    REFERENCE_DATA: environment.referenceDataAPI
};
