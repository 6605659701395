export const environment = {
    fileSizeLimit: '1GB',
    production: false,
    apiURL: 'https://devservices.sequation.net',
    logo: `./assets/logo/keyworker_logo.png`,
    brandLogo: './assets/logo/keyworker_brand.png',
    appTitle: 'Virtual Campus',
    appInsights: {
        instrumentationKey: '451221f0-7c79-4813-9ea3-a8b385b2fd5b',
        disableExceptionTracking: true
    },
    azureBlobStorage: 'https://sequationdevsa.blob.core.windows.net',
    kwIssuer: 'https://login.microsoftonline.com/5778ddf3-0ec2-4912-9f7c-6eb3674b2682',
    kwClientId: '79b4d9e7-696b-44d3-afed-d21c44ca30ed',
    kwRedirectUri: 'https://vckw-dev.sequation.net/sessions/signin',
     referenceDataAPI: 'https://devservices.sequation.net/sequation-reference-data-api'
};

